const messages = {

    ES:{
        translations:{
            titles:{
                app:'Introduzca el número de WhatsApp ',
                appi:'que pretende espiar',
                menBox:'WhatsApp total identificado',
                titulo:'Herramienta actualizada:',
                tituloseq:'Investigaciones en curso ahora:',
                seq1:'Términos de uso - Políticas de privacidad',
                seq2:'Investigación en curso...',
                seq3:'Evidencia recopilada',
                seq4:'Ver todas las conversaciones encontradas en este dispositivo',
                tax1:'Para usar PreviewSpy y conectarse en su celular',
                tax2:', requerimos un pequeño aporte simbólico de US$ 19,87.',
                tit:'cargando...'

            },
            messages:{
                inputtext:'(00) 0000-0000',
                itWorks:'¡La investigación se completó con éxito!',
                itTitulo:'O PreviewSpy',
                itExamino:'examinado más de',
                itMens:'mensajes',
                itSuspesojos:'sospechas',
                itdestacados:'y estos son algunos de los más',
                itMen:'notable:',
                boxmensage:'mensajes',
                boxmensagem:'contenía la palabra',
                boxmensg:'similar',
                boxquente:'"cálido"',
                contmensagem:'41 mensajes',
                contm:'contenía la palabra',
                contme:'similar',
                contmen:'"Amor"',
                mes:'8 mensajes',
                messa:'contenía la palabra',
                messag:'"Secreto"',
                message:'similar',
                pl:'3 ubicaciones de ubicación compartida',
                pla:'Sospechas',
                plan:'en la ciudad de',
                ma:'Uno',
                mar:'del',
                mari:'3 ubicaciones',
                maria:'compartido',
                marian:'está relacionado con la siguiente ubicación:',
                mariana:'Motel',
                pa: '2 conversaciones',
                pad:'archivados fueron',
                pada:'marcado como',
                padar:'Sospechas',
                lo:'Ver la vista previa de',
                lot:'9 fotos' ,
                loti:'recibió',
                
            },
            buttons:{
                save:'Rastrear celular',
                btn:'VER PRUEBA',
                taxa:'vea ahora',
                btnclicou:'Pressione aqui'
            },
            boxpainel:{
                acesso:'Conexión al panel',
                info:'Información móvil',
                loc:'Localizando teléfono',

            }


        }
    }


    

}

export {messages}